import './styles/orderPageStyle';
import React from 'react';
import { OrderPageContextProvider } from 'page/order/context/orderPageContext';
import OrderPageContent from './orderPageContent';
import OrderPageMainLayout from './orderPageMainLayout';
import { useCurrentUserInitialFetchDone } from '../../state/currentUserState';
import { ShSpinner } from '@shoootin/components-web';

const OrderPage = () => {
  // We need to do a request to be sure weither or not the user is auth (though the request may fail...)
  // We need to wait until request completion before showing the order content.
  // Using localstorage is not reliable enough, and we need to accurately know
  // in advance if the order breadcrumb should contain a login step
  const isUserFetchDone = useCurrentUserInitialFetchDone();
  return (
    <OrderPageMainLayout>
      {isUserFetchDone ? (
        <OrderPageContextProvider>
          <OrderPageContent />
        </OrderPageContextProvider>
      ) : (
        <ShSpinner size={'m'} />
      )}
    </OrderPageMainLayout>
  );
};

export default OrderPage;
